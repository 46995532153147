import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import TitleCard from "../components/title_card";
import { AppDispatch, RootState } from "../app/store"; // Adjust the import according to your store's setup
import { deleteItem, getItemsContent, resetDeleteItemStatus, setCurrentPage, setSearchString, setSelectedItem } from "../features/itemsSlice";
import { FaEdit as EditIcon, FaCopy as DuplicateIcon, FaTrash as DeleteIcon } from "react-icons/fa";
import noImageAvailable from "../assets/no_image.png";
import { ApiEndpoints } from "../api/endpoints";
import { useNavigate } from "react-router-dom";
import { Item } from "../modal/Item";
import { Icon, IconButton, Spinner } from "../styles/GlobalStyles";
import useConfirmation from "../components/confirm_modal";
import { toast, ToastContainer } from "react-toastify";
import { getImagePath } from "../utils/utils";

// Styled components for pagination
const MyPaginate = styled(ReactPaginate).attrs({
    activeClassName: "active",
})`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
    padding: 0 5px;

    li a {
        border-radius: 7px;
        padding: 0.1rem 1rem;
        border: gray 1px solid;
        cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
    }
    li.active a {
        background-color: #0366d6;
        border-color: transparent;
        color: white;
        min-width: 32px;
    }
    li.disabled a {
        color: grey;
    }
    li.disable,
    li.disabled a {
        cursor: default;
    }
`;

// Styled components for layout and styling
const Container = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const SearchBar = styled.div`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
        padding: 5px 15px;

`;

const Input = styled.input`
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

// Define the type for the Button component's props
interface ButtonProps {
    primary?: boolean; // Optional boolean prop for primary styling
}

// Extend the Button component with the ButtonProps type
const Button = styled.button<ButtonProps>`
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;

    ${({ primary }) =>
        primary
            ? `
        background-color: #4CAF50;
        color: white;
        border: 1px solid #4CAF50;
    `
            : `
        background-color: #e2e8f0;
        color: #2d3748;
        border: 1px solid #cbd5e0;
    `}
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        padding: 0.5rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #f4f4f4;
    }

    td {
        &:nth-child(1) {
            width: 20%;
        }
        &:nth-child(2) {
            width: 20%;
        }
        &:nth-child(3) {
            width: 10%;
        }
        &:nth-child(4) {
            width: 40%; // Adjusted to prevent overflow
        }
        &:nth-child(5) {
            width: 10%;
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const TopSideButtons = styled.div`
    display: inline-block;
    float: right;
            padding-right: 0.5rem;

`;

const ItemsPage: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { confirm, ConfirmationModal } = useConfirmation();

    const { items, isNeedToRefresh, isLoading, currentPage, totalItems, deleteItemStatus, deleteItemError, deleteItemId, isDeleteItemInprogress } = useSelector(
        (state: RootState) => state.items
    );

    const [searchQuery, setSearchQuery] = useState<string>("");

    const itemsPerPage = 20;

    const handleSearch = () => {
        dispatch(setCurrentPage(1));
        dispatch(setSearchString(searchQuery));
        dispatch(getItemsContent());
    };

    const handleResetSearch = () => {
        setSearchQuery("");
        dispatch(setSearchString(""));
        dispatch(setCurrentPage(1));
        dispatch(getItemsContent());
    };

    const handlePageChange = (selectedPage: { selected: number }) => {
        dispatch(setCurrentPage(selectedPage.selected + 1));
    };

    useEffect(() => {
        dispatch(getItemsContent());
    }, [currentPage, isNeedToRefresh, dispatch]);

    const openUpdateItemScreen = (item: Item) => {
        dispatch(setSelectedItem(item));
        navigate(`/dashboard/items/details/${item.id}`);
    };

    const deleteItemAction = async (item: Item) => {
        const isConfirmed = await confirm(`Are you sure you want to delete this box ${item.name}. Do you want to proceed?`);
        if (!isConfirmed) return;

        // Dispatch the async thunk action
        dispatch(deleteItem({ id: item.id! }))
            .unwrap()
            .then((result) => {
                console.log("Item deleted successfully!", result);
            })
            .catch((error) => {
                console.error("Failed to delete the Item:", error);
            });
    };

    const handleAddNewItemClick = () => {
        dispatch(setSelectedItem(null));
        navigate(`/dashboard/items/details`);
    };


    // Handle updateBox status changes
    useEffect(() => {
        if (deleteItemStatus === "succeeded") {
            toast("Item deleted", {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            dispatch(resetDeleteItemStatus());
        } else if (deleteItemStatus === "failed") {
            toast(`Error deleting Item: ${deleteItemError}`, {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            dispatch(resetDeleteItemStatus());
        }
    }, [deleteItemStatus, deleteItemError, dispatch]);

    return (
        <TitleCard
            title="Current Items"
            topMargin="mt-2"
            TopSideButtons={
                <TopSideButtons>
                    <Button primary onClick={handleAddNewItemClick}>
                        Add New
                    </Button>
                </TopSideButtons>
            }
        >
            <Container>
                <ToastContainer />
                <SearchBar>
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Button primary onClick={handleSearch}>
                        Search
                    </Button>
                    <Button onClick={handleResetSearch}>Reset</Button>
                </SearchBar>
                <StyledTable>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Tag</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items &&
                            items.map((l, k) => {
                                let pic = l.picture;
                                const isImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic);

                                if (isImage) {
                                    pic = getImagePath(pic);
                                }

                                return (
                                    <tr key={k}>
                                        <td>
                                            <ImageWrapper>
                                                <div>
                                                    { isImage ? (
                                                        <img
                                                            src={pic}
                                                            alt="Avatar"
                                                            style={{ width: '48px', height: '48px', objectFit: 'contain' }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={noImageAvailable}
                                                            style={{ width: '48px', height: '48px', objectFit: 'contain' }}
                                                            alt="No Image Available"
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    <div style={{ fontWeight: 'bold' }}>{l.name}</div>
                                                </div>
                                            </ImageWrapper>
                                        </td>
                                        <td>{l.tags.join(", ")}</td>
                                        <td>{"$" + l.price}</td>
                                        <td>{l.description}</td>
                                        <td>
                                            <IconButton onClick={() => openUpdateItemScreen(l)}>
                                                <Icon><EditIcon /></Icon>
                                            </IconButton>
                                            <IconButton disabled={isDeleteItemInprogress} onClick={() => deleteItemAction(l)} paddingRight="0px">
                                                {l.id === deleteItemId && deleteItemStatus === 'loading' ? (
                                                    <Spinner />
                                                ) : (
                                                    <Icon><DeleteIcon /></Icon>
                                                )}
                                            </IconButton>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </StyledTable>
                <ConfirmationModal />
            </Container>
            <MyPaginate
                pageCount={Math.ceil(totalItems / itemsPerPage)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                breakLabel="..."
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={currentPage - 1}
            />
        </TitleCard>
    );
};

export default ItemsPage;
