import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const NewSettingModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: string, key: string, value: string) => void;
  editId?: string;
  editKey?: string;
  editValue?: string;
}> = ({ isOpen, onClose, onSave, editId = '', editKey = '', editValue = '' }) => {
  const [keyInput, setKeyInput] = useState(editKey);
  const [valueInput, setValueInput] = useState(editValue);

  useEffect(() => {
    setKeyInput(editKey);
    setValueInput(editValue);
  }, [editKey, editValue, isOpen]);

  const handleSubmit = () => {
    if (keyInput && valueInput) {
      onSave(editId || '', keyInput, valueInput);
    } else {
      toast.error('Both key and value are required');
    }
  };

  if (!isOpen) return null;

  return (
    <ModalBackdrop>
      <ModalContainer>
        <ModalHeader>
          <h3>{editKey ? 'Edit Setting' : 'Add New Setting'}</h3>
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="Key"
            value={keyInput}
            onChange={(e) => setKeyInput(e.target.value)}
            disabled={!!editKey}
          />
          <Input
            type="text"
            placeholder="Value"
            value={valueInput}
            onChange={(e) => setValueInput(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <SaveButton onClick={handleSubmit}>Save</SaveButton>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </ModalFooter>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default NewSettingModal;

// Styled components here...


const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  margin-bottom: 15px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px);
  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const SaveButton = styled.button`
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const CancelButton = styled.button`
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #c82333;
  }
`;
