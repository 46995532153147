import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api, apiWithoutRestriction } from './appSlice';
import { Banner } from '../modal/banner';
import { AppDispatch, RootState } from "../app/store";

// Define the initial state
interface BannerState {
  selectedBanner: Banner | null;
  banners: Banner[];
  fetchBannersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fetchBannersError: string | null;
  isFetchBannersInprogress: boolean;
  addBannersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  isAddBannerInProgress: boolean;
  addBannersError: string | null;
  deleteBannerStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  deleteBannerError: string | null;
  isDeleteBannerInprogress: boolean;
  deleteBannerId: string | null;
  updateBannerOrderOnServerStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateBannerOrderOnServerError: string | null;
  updateBannerOrderInProgress: boolean;
  updateBannersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  isUpdateBannerInProgress: boolean;
  updateBannersError: string | null;
}

const initialState: BannerState = {
  selectedBanner: null,
  banners: [],
  fetchBannersStatus: 'idle',
  fetchBannersError: null,
  isFetchBannersInprogress: false,
  addBannersStatus: 'idle',
  isAddBannerInProgress: false,
  addBannersError: null,
  deleteBannerStatus: 'idle',
  deleteBannerError: null,
  isDeleteBannerInprogress: false,
  deleteBannerId: null,
  updateBannerOrderOnServerStatus: 'idle',
  updateBannerOrderOnServerError: null,
  updateBannerOrderInProgress: false,
  updateBannersStatus: 'idle',
  isUpdateBannerInProgress: false,
  updateBannersError: null,
};

// Async action to fetch banners
export const fetchBanners = createAsyncThunk<Banner[], string>(
  'banners/fetchBanners',
  async (active: string) => {
    const params = new URLSearchParams();
    if (active !== 'all') {
      params.append('active', active);
    }

    const response = await apiWithoutRestriction.get('/banners', { params });
    return response.data;
  }
);

// Async action to add a new banner
export const addBanner = createAsyncThunk<
  { detail: string; slide: Banner },
  { image: string; order_id: number; active: boolean; },
  { rejectValue: string }
>(
  'banners/addBanner',
  async ({ image, order_id, active }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/banner/add?image=${image}&order_id=${order_id}&active=${active}`
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('An unexpected error occurred.');
    }
  }
);

// Async action to add a new banner
export const updateBanner = createAsyncThunk<
  void,
  { banner_id: String, image: string; order_id: number; active: boolean; },
  { rejectValue: string }
>(
  'banners/updateBanner',
  async ({ banner_id, image, order_id, active }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/banner/edit?banner_id=${banner_id}&image=${image}&order_id=${order_id}&active=${active}`
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('An unexpected error occurred.');
    }
  }
);

// Async action to delete a banner
export const deleteBanner = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch, state: RootState, rejectValue: string }
>(
  'banners/deleteBanner',
  async (banner_id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await api.post(`/banner/delete?banner_id=${banner_id}`);
      // Get current state and access the banners
      const state = getState();
      var bannersWithoutDeletedItem = state.banners.banners.filter(banner => banner.id !== banner_id);
      // Dispatch the updateBannerOrderOnServer action with the current banners state
      dispatch(updateBannerOrderOnServer(bannersWithoutDeletedItem));
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('Failed to delete banner');
    }
  }
);


// Async action to update banner order on server
export const updateBannerOrderOnServer = createAsyncThunk<
  void,
  Banner[],
  { dispatch: AppDispatch, rejectValue: string }
>(
  'banners/updateBannerOrderOnServer',
  async (banners, { dispatch, rejectWithValue }) => {
    const payload = banners.map((banner, index) => ({
      slide_id: banner.id, // Use banner.id for slide_id
      order_id: index // Use index for order_id
    }));

    try {
      const response = await api.post(
        '/banner/update-slides-order',
        payload
      );

      dispatch(fetchBanners('all')); // Now dispatch is available here
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('Failed to update banner order.');
    }
  }
);

const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    setSelectedBanner: (state, action: PayloadAction<Banner | null>) => {
      state.selectedBanner = action.payload;
    },
    resetAddBannersStatus: (state) => {
      state.addBannersStatus = 'idle';
      state.addBannersError = null;
    },
    resetUpdateBannersStatus: (state) => {
      state.updateBannersStatus = 'idle';
      state.updateBannersError = null;
    },
    resetDeleteBannerStatus: (state) => {
      state.deleteBannerStatus = 'idle';
      state.deleteBannerError = null;
      state.isDeleteBannerInprogress = false;
      state.deleteBannerId = null;
    },
    updateBannerOrder: (state, action) => {
      state.banners = action.payload; // Set new order of banners
    },
  },
  extraReducers: (builder) => {
    // Handle the fetch banners cases
    builder.addCase(fetchBanners.pending, (state) => {
      state.fetchBannersStatus = 'loading';
      state.fetchBannersError = null;
      state.isFetchBannersInprogress = true;
    });
    builder.addCase(fetchBanners.fulfilled, (state, action: PayloadAction<Banner[]>) => {
      state.fetchBannersStatus = 'succeeded';
      // Sort the banners by order_id before assigning to state
      const sortedBanners = action.payload.sort((a, b) => a.order_id - b.order_id);
      state.banners = sortedBanners;
      state.isFetchBannersInprogress = false;
    });
    builder.addCase(fetchBanners.rejected, (state, action) => {
      state.fetchBannersStatus = 'failed';
      state.fetchBannersError = action.error.message || 'Failed to fetch banners';
      state.isFetchBannersInprogress = false;
    });
    builder.addCase(addBanner.pending, (state) => {
      state.addBannersStatus = 'loading';
      state.addBannersError = null;
      state.isAddBannerInProgress = true;
    });
    builder.addCase(addBanner.fulfilled, (state, action: PayloadAction<{ detail: string; slide: Banner }>) => {
      state.addBannersStatus = 'succeeded';
      state.isAddBannerInProgress = false;
      state.selectedBanner = action.payload.slide;
    });
    builder.addCase(addBanner.rejected, (state, action) => {
      state.addBannersStatus = 'failed';
      state.addBannersError = action.payload || 'Failed to add banner';
      state.isAddBannerInProgress = false;
    });
    builder.addCase(deleteBanner.pending, (state, action) => {
      state.deleteBannerStatus = 'loading';
      state.deleteBannerError = null;
      state.isDeleteBannerInprogress = true;
      state.deleteBannerId = action.meta.arg; // Store the banner_id being deleted
    });
    builder.addCase(deleteBanner.fulfilled, (state) => {
      state.deleteBannerStatus = 'succeeded';
      state.isDeleteBannerInprogress = false;
      state.deleteBannerId = null;
    });
    builder.addCase(deleteBanner.rejected, (state, action) => {
      state.deleteBannerStatus = 'failed';
      state.deleteBannerError = action.payload || 'Failed to delete banner';
      state.isDeleteBannerInprogress = false;
    });
    builder.addCase(updateBannerOrderOnServer.pending, (state) => {
      state.updateBannerOrderOnServerStatus = 'loading';
      state.updateBannerOrderOnServerError = null;
      state.updateBannerOrderInProgress = true;
    });
    builder.addCase(updateBannerOrderOnServer.fulfilled, (state) => {
      state.updateBannerOrderOnServerStatus = 'succeeded';
      state.updateBannerOrderInProgress = false;
    });
    builder.addCase(updateBannerOrderOnServer.rejected, (state, action) => {
      state.updateBannerOrderOnServerStatus = 'failed';
      state.updateBannerOrderOnServerError = action.payload || 'Failed to update banner order.';
      state.updateBannerOrderInProgress = false;
    });
    builder.addCase(updateBanner.pending, (state) => {
      state.updateBannersStatus = 'loading';
      state.updateBannersError = null;
      state.isUpdateBannerInProgress = true;
    });
    builder.addCase(updateBanner.fulfilled, (state) => {
      state.updateBannersStatus = 'succeeded';
      state.isUpdateBannerInProgress = false;
    });
    builder.addCase(updateBanner.rejected, (state, action) => {
      state.updateBannersStatus = 'failed';
      state.updateBannersError = action.payload || 'Failed to add banner';
      state.isUpdateBannerInProgress = false;
    });
  },
});

export const { setSelectedBanner, resetAddBannersStatus, resetDeleteBannerStatus, updateBannerOrder, resetUpdateBannersStatus } = bannerSlice.actions;

export default bannerSlice.reducer;
