import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import { Icon, IconButton, Spinner } from '../styles/GlobalStyles';
import { FaEdit as EditIcon, FaTrash as DeleteIcon } from "react-icons/fa";
import { AppDispatch, RootState } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSetting,
  deleteSetting,
  fetchSettings,
  resetAddSettingStatus,
  resetDeleteSettingStatus,
  resetUpdateSettingStatus,
  updateSetting,
} from '../features/settingssSlice';
import { openModal, closeModal } from '../features/settingModalSlice';
import NewSettingModal from '../components/new_settings_modal';

const SettingsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    settings,
    addSettingStatus,
    updateSettingStatus,
    deleteSettingStatus,
    isUpdateSettingInProgress,
    isDeleteSettingInprogress,
    deleteSettingId,
    updateSettingId,
  } = useSelector((state: RootState) => state.settings);

  const { isOpen, editId, editKey, editValue } = useSelector((state: RootState) => state.settingsModal);

  const [isSuperUser] = useState(false);

  // Load settings when the component mounts
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleSave = (id: string, key: string, value: string) => {
    if (id) {
      dispatch(updateSetting({ setting_id: id, key, value }));
    } else {
      dispatch(addSetting({ key, value }));
    }
    dispatch(closeModal()); // Close the modal after saving
  };

  const handleEdit = (index: number) => {
    const setting = settings[index];
    dispatch(openModal({ editId: setting.id, editKey: setting.setting, editValue: setting.value }));
  };

  const handleDelete = (index: number) => {
    dispatch(deleteSetting(settings[index].id));
  };

  useEffect(() => {
    if (deleteSettingStatus === 'succeeded') {
      toast.success('Setting deleted');
      dispatch(resetDeleteSettingStatus());
    } else if (deleteSettingStatus === 'failed') {
      toast.error('Failed to delete setting');
      dispatch(resetDeleteSettingStatus());
    }
  }, [deleteSettingStatus, dispatch]);

  useEffect(() => {
    if (updateSettingStatus === 'succeeded') {
      toast.success('Setting updated');
      dispatch(resetUpdateSettingStatus());
    } else if (updateSettingStatus === 'failed') {
      toast.error('Failed to update setting');
      dispatch(resetUpdateSettingStatus());
    }
  }, [updateSettingStatus, dispatch]);

  useEffect(() => {
    if (addSettingStatus === 'succeeded') {
      toast.success('Setting added');
      dispatch(resetAddSettingStatus());
    } else if (addSettingStatus === 'failed') {
      toast.error('Failed to add setting');
      dispatch(resetAddSettingStatus());
    }
  }, [addSettingStatus, dispatch]);

  return (
    <Container>
      <ToastContainer />
      <Header>
        <Title>Settings</Title>
        {isSuperUser && (
          <NewBannerButton onClick={() => dispatch(openModal({}))}>
            {addSettingStatus === 'loading' ? <Spinner /> : '+ New Setting'}
          </NewBannerButton>
        )}
      </Header>

      <Table>
        <thead>
          <tr>
            <Th>Key</Th>
            <Th>Value</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {settings.map((setting, index) => (
            <tr key={setting.id}>
              <Td>{setting.setting}</Td>
              <Td>{setting.value}</Td>
              <Td>
                <IconButton disabled={isUpdateSettingInProgress} onClick={() => handleEdit(index)}>
                  {setting.id === updateSettingId && updateSettingStatus === 'loading' ? <Spinner /> : <Icon><EditIcon /></Icon>}
                </IconButton>

                {isSuperUser && (
                  <IconButton disabled={isDeleteSettingInprogress} onClick={() => handleDelete(index)}>
                    {setting.id === deleteSettingId && deleteSettingStatus === 'loading' ? <Spinner /> : <Icon><DeleteIcon /></Icon>}
                  </IconButton>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>

      <NewSettingModal
        isOpen={isOpen}
        onClose={() => dispatch(closeModal())}
        onSave={handleSave}
        editId={editId || ''}
        editKey={editKey}
        editValue={editValue}
      />
    </Container>
  );
};

export default SettingsPage;


// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const NewBannerButton = styled.button`
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ccc;
`;

const Th = styled.th`
  padding: 12px 15px;
  background-color: #f1f1f1;
  text-align: left;
  color: #333;
`;

const Td = styled.td`
  padding: 12px 15px;
  text-align: left;
  color: #333;
  border-top: 1px solid #ccc;
`;

