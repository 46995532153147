import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../app/store"; // Assuming you have a RootState type defined for your Redux store
import { ApiEndpoints } from "../api/endpoints";
import { Item } from "../modal/Item";
import { api } from "../features/appSlice"
import { Box } from "../modal/box";
import { handleLogoutUser } from "../utils/utils";
import { useNavigate } from "react-router-dom";


interface ItemsState {
  isLoading: boolean;
  isNeedToRefresh: boolean;
  items: Item[];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  searchString: string;
  createItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  boxesForSelectedItemError: string | null;
  boxesForSelectedItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  boxesForSelectedItem: Box[];
  selectedItem: Item | null;
  isCreatingOrUpdating: boolean;
  deleteItemStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  deleteItemError: string | null;
  isDeleteItemInprogress: boolean;
  deleteItemId: string | null;  // Track boxId being deleted
}

// Define the initial state using the ItemsState interface
const initialState: ItemsState = {
  isLoading: false,
  isNeedToRefresh: false,
  items: [],
  currentPage: 1,
  itemsPerPage: 20,
  totalItems: 0,
  searchString: "",
  createItemStatus: 'idle',
  updateItemStatus: 'idle',
  error: null,
  selectedItem: null,
  boxesForSelectedItemError: null,
  boxesForSelectedItemStatus: 'idle',
  boxesForSelectedItem: [],
  isCreatingOrUpdating: false,
  deleteItemStatus: 'idle',
  deleteItemError: null,
  isDeleteItemInprogress: false,
  deleteItemId: null,
};



export const deleteItem = createAsyncThunk<
  void,
  { id: string }, // The box_id and name will be passed as an object
  { dispatch: AppDispatch, rejectValue: string }
>(
  "/items/deleteItem",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.get(`${ApiEndpoints.ITEMS}/delete/${id}`);
      dispatch(getItemsContent()); // Now it knows how to handle getBoxesContent
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail || "Failed to delete box");
      }
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Define async thunks with proper typing
export const getItemsContent = createAsyncThunk<
  any, // The return type of the thunk
  void, // The first argument to the thunk
  {dispatch: AppDispatch,  state: RootState } // ThunkAPI type
>(
  "/items/content",
  async (_, {dispatch, getState }) => {
    try {
      const itemsState = getState().items; // Adjusted to match slice name

      if (!itemsState) {
        console.error("Items state is not defined");
        return;
      }

      const { currentPage, itemsPerPage, searchString } = itemsState;
      const offset = (currentPage - 1) * itemsPerPage;

      const queryParams: any = {
        offset,
        limit: itemsPerPage,
        retired: false,
      };

      if (searchString.trim().length > 0) {
        queryParams.q = searchString.trim();
      }

      const response = await api.get(ApiEndpoints.ITEMS, {
        params: queryParams,
      });
      return response.data;
    } catch (error: any) {
      console.error(error);
      // Check if the error is a 401
      if (error.response && error.response.status === 401) {
        // Dispatch the logout action
        handleLogoutUser(dispatch, useNavigate());
      }
      throw error;
    }
  }
);

export const createItem = createAsyncThunk<
  Item, // The return type of the thunk
  any, // The first argument to the thunk (item data)
  { rejectValue: any } // ThunkAPI type
>(
  "/items/createItem",
  async (itemData, { rejectWithValue }) => {
    try {
      const response = await api.post(ApiEndpoints.ITEMS, itemData);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const updateItem = createAsyncThunk<
  Item, // The return type of the thunk
  { id: string;[key: string]: any }, // The first argument to the thunk (item data with id)
  { rejectValue: any } // ThunkAPI type
>(
  "/items/updateItem",
  async ({ id, ...itemData }, { rejectWithValue }) => {
    try {
      const urlString = `${ApiEndpoints.ITEMS}/${id}`;
      const response = await api.post(urlString, itemData);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const getBoxesForSelectedItem = createAsyncThunk<
  Box[], // The return type of the thunk (number of boxes)
  string, // The first argument to the thunk (itemId)
  { rejectValue: any } // ThunkAPI type
>(
  "items/getBoxesForSelectedItem",
  async (itemId, { rejectWithValue }) => {
    try {
      const response = await api.get(ApiEndpoints.BOXES_COUNT_FOR_ITEM, {
        params: { item_id: itemId },
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      state.isNeedToRefresh = true;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    resetCreateItemStatus: (state) => {
      state.createItemStatus = 'idle';
      state.error = null;
    },
    resetUpdateItemStatus: (state) => {
      state.updateItemStatus = 'idle';
      state.error = null;
    },
    setSelectedItem: (state, action: PayloadAction<Item | null>) => {
      state.selectedItem = action.payload;
    },
    resetDeleteItemStatus: (state) => {
      state.deleteItemStatus = 'idle';
      state.deleteItemError = null;
      state.deleteItemId = null; // Reset the boxId being tracked
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItemsContent.pending, (state) => {
        state.isLoading = true;
        state.isNeedToRefresh = false;
      })
      .addCase(getItemsContent.fulfilled, (state, action) => {
        console.log(action.payload.list);
        state.items = action.payload.list;
        state.totalItems = action.payload.total;
        state.isLoading = false;
        state.isNeedToRefresh = false;
      })
      .addCase(getItemsContent.rejected, (state) => {
        state.isLoading = false;
        state.isNeedToRefresh = false;
      })
      .addCase(createItem.pending, (state) => {
        state.isLoading = true;
        state.isNeedToRefresh = false;
        state.createItemStatus = 'loading';
        state.error = null;
        state.isCreatingOrUpdating = true;
      })
      .addCase(createItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = true;
        state.createItemStatus = 'succeeded';
        state.selectedItem = action.payload;
        state.isCreatingOrUpdating = false;
      })
      .addCase(createItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = false;
        state.createItemStatus = 'failed';
        state.error = action.payload ? action.payload.detail : action.error.message;
        state.isCreatingOrUpdating = false;
      })
      .addCase(updateItem.pending, (state) => {
        state.isLoading = true;
        state.isNeedToRefresh = false;
        state.updateItemStatus = 'loading';
        state.error = null;
        state.isCreatingOrUpdating = true;
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = true;
        state.updateItemStatus = 'succeeded';
        state.selectedItem = action.payload;
        state.isCreatingOrUpdating = false;
      })
      .addCase(updateItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = false;
        state.updateItemStatus = 'failed';
        state.error = action.payload ? action.payload.detail : action.error.message;
        state.isCreatingOrUpdating = false;
      })
      .addCase(getBoxesForSelectedItem.pending, (state) => {
        state.boxesForSelectedItemStatus = 'loading';
        state.boxesForSelectedItemError = null;
      })
      .addCase(getBoxesForSelectedItem.fulfilled, (state, action) => {
        state.boxesForSelectedItemStatus = 'succeeded';
        state.boxesForSelectedItem = action.payload;
      })
      .addCase(getBoxesForSelectedItem.rejected, (state, action) => {
        state.boxesForSelectedItemStatus = 'failed';
        state.boxesForSelectedItemError = action.payload ? action.payload.detail : action.error.message;
      })
      .addCase(deleteItem.pending, (state, action) => {
        state.deleteItemStatus = 'loading';
        state.deleteItemError = null;
        state.isDeleteItemInprogress = true;
        state.deleteItemId = action.meta.arg.id;
      })
      .addCase(deleteItem.fulfilled, (state) => {
        state.deleteItemStatus = 'succeeded';
        state.isDeleteItemInprogress = false;
        state.isNeedToRefresh = true; // Refresh the boxes list if needed
        state.deleteItemId = null; // Clear boxId after failure
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.deleteItemStatus = 'failed';
        state.deleteItemError = action.payload || action.error.message || "Failed to delete item";
        state.isDeleteItemInprogress = false;
        state.deleteItemId = null; // Clear boxId after failure
      });
  },
});

export const { setCurrentPage, setSearchString, resetCreateItemStatus, resetUpdateItemStatus, setSelectedItem, resetDeleteItemStatus } = itemsSlice.actions;

export default itemsSlice.reducer;
