import React from 'react';

interface VideoComponentProps {
  videoBase64: string; // Base64 string for the video
}

const VideoComponent: React.FC<VideoComponentProps> = ({ videoBase64 }) => {
  return (
    <div>
      <video width="600" controls>
        <source src={videoBase64} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
