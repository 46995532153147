import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "./appSlice";
import { ApiEndpoints } from "../api/endpoints";

// Define the shape of the video data
interface VideoState {
  videos: string[]; // Array of base64 encoded video strings
  createVideoStatus: "idle" | "loading" | "succeeded" | "failed";
  createVideoError: string | null;
  isCreateVideoInprogress: boolean;
}

// Define the initial state for the video slice
const initialState: VideoState = {
  videos: [],
  createVideoStatus: "idle",
  createVideoError: null,
  isCreateVideoInprogress: false,
};

// Define the async thunk for creating a video
export const createVideo = createAsyncThunk<
  string, // Type for the resolved value
  object, // Type for the argument
  { rejectValue: { message: string } } // Type for rejected value
>("videos/createVideo", async (videoData, { rejectWithValue }) => {
  try {
    let ep = `${ApiEndpoints.RENDER_VIDEO}`;

    const response = await api.post(ep, videoData);

    // Return parsed response data directly
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(
        error.response.data.detail || "Failed to create video"
      );
    }
    return rejectWithValue(error.message || "An error occurred");
  }
});

// Create the video slice
const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    resetCreateVideoStatus: (state) => {
      state.createVideoStatus = "idle";
      state.createVideoError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVideo.pending, (state) => {
        state.createVideoStatus = "loading";
        state.isCreateVideoInprogress = true;
        state.createVideoError = null; // Clear previous errors
        state.videos = [];
      })
      .addCase(
        createVideo.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isCreateVideoInprogress = false;
          state.createVideoStatus = "succeeded";

          // Log the entire payload to understand its structure
          console.log("Payload:", action.payload);

          try {
            // The payload might actually be a string that needs to be parsed first
            const response = JSON.parse(action.payload);

            // Check if the video_base64 property exists
            if (response && response.video_base64) {
              let base64String = response.video_base64;

              // Check if the base64 string has a prefix and remove it if necessary
              const base64Prefix = "data:video/mp4;base64,";
              if (base64String.startsWith(base64Prefix)) {
                base64String = base64String.substring(base64Prefix.length);
              }

              // Ensure the base64 string has valid padding
              const padding = base64String.length % 4;
              if (padding > 0) {
                base64String += "=".repeat(4 - padding); // Add padding
              }

              // Decode base64 string to binary data
              const byteCharacters = atob(base64String); // Decode base64 string
              const byteNumbers = new Uint8Array(byteCharacters.length); // Create a new Uint8Array

              // Convert each character to its byte value
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }

              // Create a Blob from the byte array
              const blob = new Blob([byteNumbers], { type: "video/mp4" }); // Adjust MIME type as necessary
              const url = URL.createObjectURL(blob); // Create a Blob URL

              // Save the Blob URL to the state
              state.videos.push(url);

              console.log("Video URL:", url);

            //   // Revoke the object URL after use to free up memory
            //   setTimeout(() => {
            //     // URL.revokeObjectURL(url);
            //     state.videos = [];
            //   }, 10000); // Adjust the timeout as needed
            } else {
              console.error(
                "video_base64 property not found in response:",
                response
              );
            }
          } catch (error) {
            console.error("Error parsing response:", error);
            // Optionally set an error state if decoding fails
          }
        }
      )

      .addCase(
        createVideo.rejected,
        (state, action: PayloadAction<{ message: string } | undefined>) => {
          state.isCreateVideoInprogress = false;
          state.createVideoStatus = "failed";
          state.createVideoError =
            action.payload?.message || "Failed to create video";
        }
      );
  },
});

// Export actions and reducer
export const { resetCreateVideoStatus } = videoSlice.actions;
export default videoSlice.reducer;
