import React from 'react';
import Select, { SingleValue, components, StylesConfig } from 'react-select';
import styled from 'styled-components';
import noImageAvailable from "../assets/no_image.png";
import { Item } from '../modal/Item';
import { getImagePath } from '../utils/utils';

// Define the type for options
interface ItemOption {
  value: string;
  label: string;
  imageUrl: string;
}

// Styled component for the image
const Image = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
    object-fit: contain; /* Ensures the entire image is visible within the container while maintaining its aspect ratio */
  background-color: #f0f0f0; /* Light background color */
  display: block; /* Ensures the image takes up the container space properly */

`;

// Utility function to determine if the URL is a Base64 string or an image URL
const getImageSrc = (imageUrl: string) => {
  const isImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(imageUrl);

  if (isImage) {
    return getImagePath(imageUrl);
  }

  return noImageAvailable; // Fallback image
};

// Custom SingleValue component
const SingleValueComponent = (props: any) => {
  const imageSrc = getImageSrc(props.data.imageUrl);
  return (
    <components.SingleValue {...props}>
      <Image src={imageSrc} alt={props.data.label} />
      {props.data.label}
    </components.SingleValue>
  );
};

// Custom Option component
const OptionComponent = (props: any) => {
  const imageSrc = getImageSrc(props.data.imageUrl);
  return (
    <components.Option {...props}>
      <Image src={imageSrc} alt={props.data.label} />
      {props.data.label}
    </components.Option>
  );
};

// Define props for the ItemSelect component
interface ItemSelectProps {
  items: Item[];
  item: { name: string };
  index: number;
  handleItemSelect: (index: number, value: string | null) => void;
  disabled?: boolean; // Add a disabled prop
}


// Main ItemSelect component
const ItemSelect: React.FC<ItemSelectProps> = ({ items, item, index, handleItemSelect, disabled = false }) => {
  // Map items to include imageUrl
  const options: ItemOption[] = items.map(i => ({
    value: i.name,
    label: i.name,
    imageUrl: i.picture,
  }));

  // Custom styles for react-select
  const customStyles: StylesConfig<ItemOption, false> = {
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      opacity: disabled ? 0.5 : 1, // Adjust the opacity if disabled
      pointerEvents: disabled ? 'none' : 'auto', // Disable interaction when disabled
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  return (
    <Select
      value={options.find(option => option.value === item.name)}
      onChange={(e: SingleValue<ItemOption>) => {
        if (e) {
          handleItemSelect(index, e.value);
        } else {
          handleItemSelect(index, null); // Handle null case as required
        }
      }}
      options={options}
      placeholder="Search and select an item..."
      isClearable
      noOptionsMessage={() => 'No options available. Please search.'}
      components={{ SingleValue: SingleValueComponent, Option: OptionComponent }}
      styles={customStyles} // Apply custom styles
    />
  );
};

export default ItemSelect;
