// src/components/SortableRow.tsx

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FiMove } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBanner, setSelectedBanner } from '../features/bannersSlice';
import { AppDispatch, RootState } from '../app/store';
import { Banner } from '../modal/banner';
import { Icon, IconButton, Spinner } from '../styles/GlobalStyles';
import { FaEdit as EditIcon, FaTrash as DeleteIcon } from "react-icons/fa";
import { styled } from 'styled-components';
import { ApiEndpoints } from '../api/endpoints';
import { useNavigate } from 'react-router-dom';
import { getImagePath } from '../utils/utils';

const SortableRow: React.FC<{ banner: Banner; index: number; confirm: (message: string) => Promise<boolean>; }> = ({ banner, index, confirm }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: banner.id });
  const { isDeleteBannerInprogress, deleteBannerId, deleteBannerStatus, updateBannerOrderInProgress } = useSelector((state: RootState) => state.banners);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deleteBannerAction = async (banner: Banner) => {
    const isConfirmed = await confirm(`Are you sure you want to delete this banner ${banner.image}?`);
    if (!isConfirmed) return;

    dispatch(deleteBanner(banner.id))
      .unwrap()
      .then(() => {
        console.log("Banner deleted successfully!");
      })
      .catch((error) => {
        console.error("Failed to delete the Banner:", error);
      });
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // Handle edit action here
    dispatch(setSelectedBanner(banner));
    navigate(`/dashboard/banners/details/${banner.id}`);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    deleteBannerAction(banner);
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <Td {...attributes} {...listeners}>
        <DragIcon>
          <FiMove />
        </DragIcon>
      </Td>

      <Td>
        <img
          src={ getImagePath(banner.image)}
          alt="Banner"
          style={{ width: '50px', height: '50px', borderRadius: '4px', objectFit: 'cover' }}
        />
      </Td>
      <Td>{index + 1}</Td>
      <Td>
        <CheckIcon active={banner.active} />
      </Td>
      <Td>{ getImagePath(banner.image)}</Td>
      <Td>
        <div style={{ display: 'flex', gap: '5px' }}>
          <IconButton
            disabled={isDeleteBannerInprogress || updateBannerOrderInProgress}
            onClick={handleEditClick}
          >
            <Icon><EditIcon /></Icon>
          </IconButton>
          <IconButton
            disabled={isDeleteBannerInprogress}
            onClick={handleDeleteClick}
          >
            {banner.id === deleteBannerId && deleteBannerStatus === 'loading' ? (
              <Spinner />
            ) : (
              <Icon><DeleteIcon /></Icon>
            )}
          </IconButton>
        </div>
      </Td>
    </tr>
  );
};

export default SortableRow;


const DragIcon = styled.div`
  cursor: grab;
  color: #888;
`;

const Td = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
`;


const CheckIcon = styled.div<{ active: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ active }) => (active ? '#28a745' : '#dc3545')};
  border-radius: 50%;
`;

