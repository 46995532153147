import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api, apiWithoutRestriction } from './appSlice';
import { AppDispatch } from "../app/store";
import { Setting } from '../modal/setting';

// Define the initial state
interface SettingsState {
  selectedSetting: Setting | null;
  settings: Setting[];
  fetchSettingsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fetchSettingsError: string | null;
  isFetchSettingInprogress: boolean;
  addSettingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  isAddSettingInProgress: boolean;
  addSettingError: string | null;
  deleteSettingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  deleteSettingError: string | null;
  isDeleteSettingInprogress: boolean;
  deleteSettingId: string | null;
  updateSettingStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  isUpdateSettingInProgress: boolean;
  updateSettingError: string | null;
  updateSettingId: string | null;

}

const initialState: SettingsState = {
  selectedSetting: null,
  settings: [],
  fetchSettingsStatus: 'idle',
  fetchSettingsError: null,
  isFetchSettingInprogress: false,
  addSettingStatus: 'idle',
  isAddSettingInProgress: false,
  addSettingError: null,
  deleteSettingStatus: 'idle',
  deleteSettingError: null,
  isDeleteSettingInprogress: false,
  deleteSettingId: null,
  updateSettingStatus: 'idle',
  isUpdateSettingInProgress: false,
  updateSettingError: null,
  updateSettingId: null,
};

// Async action to fetch banners
export const fetchSettings = createAsyncThunk<Setting[]>(
  'settings/fetchSettings',
  async () => {
    const params = new URLSearchParams();

    const response = await apiWithoutRestriction.get('/settings', { params });
    return response.data;
  }
);

// Async action to add a new banner
export const addSetting = createAsyncThunk<
  { detail: string; setting: Setting },
  { key: string; value: string; },
  { dispatch:AppDispatch, rejectValue: string }
>(
  'settings/addSetting',
  async ({ key, value }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `/setting/add?setting=${key}&value=${value}`
      );

      dispatch(fetchSettings()); // Now dispatch is available here

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('An unexpected error occurred.');
    }
  }
);

// Async action to add a new banner
export const updateSetting = createAsyncThunk<
  void,
  { setting_id: string, key: string; value: String; },
  { dispactch:AppDispatch, rejectValue: string }
>(
  'settings/updateSetting',
  async ({ setting_id, key, value }, {dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `/setting/edit?setting_id=${setting_id}&setting=${key}&value=${value}`
      );
      dispatch(fetchSettings()); // Now dispatch is available here
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('An unexpected error occurred.');
    }
  }
);

// Async action to delete a banner
export const deleteSetting = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch, rejectValue: string }
>(
  'setting/deleteSetting',
  async (setting_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`/setting/delete?setting_id=${setting_id}`);
      dispatch(fetchSettings());
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue('Failed to delete banner');
    }
  }
);




const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSelectedSetting: (state, action: PayloadAction<Setting | null>) => {
      state.selectedSetting = action.payload;
    },
    resetAddSettingStatus: (state) => {
      state.addSettingStatus = 'idle';
      state.addSettingError = null;
    },
    resetUpdateSettingStatus: (state) => {
      state.updateSettingStatus = 'idle';
      state.updateSettingError = null;
    },
    resetDeleteSettingStatus: (state) => {
      state.deleteSettingStatus = 'idle';
      state.deleteSettingError = null;
      state.isDeleteSettingInprogress = false;
      state.deleteSettingId = null;
    },
    updateBannerOrder: (state, action) => {
      state.settings = action.payload; // Set new order of banners
    },
  },
  extraReducers: (builder) => {
    // Handle the fetch banners cases
    builder.addCase(fetchSettings.pending, (state) => {
      state.fetchSettingsStatus = 'loading';
      state.fetchSettingsError = null;
      state.isFetchSettingInprogress = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action: PayloadAction<Setting[]>) => {
      state.fetchSettingsStatus = 'succeeded';
      state.settings = action.payload;
      state.isFetchSettingInprogress = false;
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.fetchSettingsStatus = 'failed';
      state.fetchSettingsError = action.error.message || 'Failed to fetch banners';
      state.isFetchSettingInprogress = false;
    });
    builder.addCase(addSetting.pending, (state) => {
      state.addSettingStatus = 'loading';
      state.addSettingError = null;
      state.isAddSettingInProgress = true;
    });
    builder.addCase(addSetting.fulfilled, (state, action: PayloadAction<{ detail: string; setting: Setting }>) => {
      state.addSettingStatus = 'succeeded';
      state.isAddSettingInProgress = false;
      state.selectedSetting = action.payload.setting;
    });
    builder.addCase(addSetting.rejected, (state, action) => {
      state.addSettingStatus = 'failed';
      state.addSettingError = action.payload || 'Failed to add banner';
      state.isAddSettingInProgress = false;
    });
    builder.addCase(deleteSetting.pending, (state, action) => {
      state.deleteSettingStatus = 'loading';
      state.deleteSettingError = null;
      state.isDeleteSettingInprogress = true;
      state.deleteSettingId = action.meta.arg; // Store the banner_id being deleted
    });
    builder.addCase(deleteSetting.fulfilled, (state) => {
      state.deleteSettingStatus = 'succeeded';
      state.isDeleteSettingInprogress = false;
      state.deleteSettingId = null;
    });
    builder.addCase(deleteSetting.rejected, (state, action) => {
      state.deleteSettingStatus = 'failed';
      state.deleteSettingError = action.payload || 'Failed to delete banner';
      state.isDeleteSettingInprogress = false;
    });
    builder.addCase(updateSetting.pending, (state, action) => {
      state.updateSettingStatus = 'loading';
      state.updateSettingError = null;
      state.isUpdateSettingInProgress = true;
      state.updateSettingId = action.meta.arg.setting_id; // Store the banner_id being deleted
    });
    builder.addCase(updateSetting.fulfilled, (state) => {
      state.updateSettingStatus = 'succeeded';
      state.isUpdateSettingInProgress = false;
    });
    builder.addCase(updateSetting.rejected, (state, action) => {
      state.updateSettingStatus = 'failed';
      state.updateSettingError = action.payload || 'Failed to add banner';
      state.isUpdateSettingInProgress = false;
    });
  },
});

export const { setSelectedSetting, resetAddSettingStatus, resetDeleteSettingStatus, updateBannerOrder, resetUpdateSettingStatus } = settingsSlice.actions;

export default settingsSlice.reducer;
