import { css, keyframes, styled } from "styled-components";
import { FaChevronDown } from "react-icons/fa";

// Define styled components
export const Container = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  color: #6b7280;
`;

export const Section = styled.section`
  margin-bottom: 16px;
`;

export const Form = styled.form`
  border: 1px solid #d1d5db;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Label = styled.label`
  margin-right: 8px;
`;

export const Input = styled.input<{ isValid?: boolean }>`
  width: 300px;
  padding: 4px;
  border: 1px solid ${(props) => (props.isValid ? "#d1d5db" : "#f87171")};
  outline: none;
  z-index: 10;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  /* Add any specific styles if required */
`;

// const Button = styled.button`
// background-color: #f97316;
// color: white;
// font-size: 0.875rem;
// padding: 4px 8px;
// border-radius: 4px;
// `;

// Define the type for the Button component's props
export interface ButtonProps {
  primary?: boolean; // Optional boolean prop for primary styling
  disabledAnimation?: boolean; // Optional boolean to control animation when disabled
}

export const Button = styled.button<ButtonProps & { disabled?: boolean }>`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  position: relative;
  overflow: hidden;

  ${({ primary, disabled }) =>
    primary
      ? disabled
        ? `
            background-color: #a5d6a7; /* Disabled green shade */
            color: white;
            border: 1px solid #a5d6a7;
            transform: none; /* Disable transform on hover */
          `
        : `
            background-color: #4CAF50;
            color: white;
            border: 1px solid #4CAF50;
            
            &:hover {
              background-color: #45a049;
              transform: scale(1.05);
            }
          `
      : disabled
      ? `
          background-color: #e2e8f0; /* Disabled gray shade */
          color: #a1a1a1; /* Disabled text color */
          border: 1px solid #cbd5e0;
          transform: none; /* Disable transform on hover */
        `
      : `
          background-color: #e2e8f0;
          color: #2d3748;
          border: 1px solid #cbd5e0;
          
          &:hover {
            background-color: #cbd5e0;
            transform: scale(1.05);
          }
        `}

  // Spinner animation when disabled
  &::after {
    content: '';
    display: ${({ disabled, disabledAnimation = true }) => 
      disabled && disabledAnimation ? 'block' : 'none'};
    position: absolute;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top: 2px solid #fff;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;


// Define keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export interface CalibrateButtonProps {
  primary?: boolean; // Optional boolean prop for primary styling
  title?: string; // Optional title prop for displaying the button title
  disabled?: boolean; // Add disabled prop to control disabled state
}

export const CalibrateButton = styled.button<CalibrateButtonProps>`
  padding: 0.4rem 0.8rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9rem;
  font-weight: 500;

  transition: background-color 0.3s ease, transform 0.2s ease;

  /* Always show the title even if the button is disabled */
  &::after {
    content: "${(props) => props.title || (props.primary ? 'Calibration Required' : 'Calibrate Items')}";
    display: block;
    font-size: 0.8rem;
  }

  ${({ primary, disabled }) =>
    disabled
      ? css`
          background-color: #f0f0f0; /* Disabled background color */
          color: #a1a1a1; /* Disabled text color */
          border: 1px solid #ddd;
          cursor: not-allowed; /* Show disabled cursor (not-allowed) */
          opacity: 0.8; /* Visual feedback for disabled state */
          transform: none; /* Disable scaling */

          /* Ensure the title is still shown in the disabled state */
          &::after {
            color: #a1a1a1; /* Disabled text color for the title */
            animation: none; /* No blinking for the title in disabled state */
          }
        `
      : primary
      ? css`
          background-color: #e63946; /* Red color for calibration required */
          color: white;
          border: 1px solid #e63946;

          &:hover {
            background-color: #d62839; /* Darker red on hover */
            transform: scale(1.05);
          }

          /* Blinking effect for the title */
          &::after {
            color: white;
            animation: ${blink} 1s infinite;
          }
        `
      : css`
          background-color: #e0e7eb; /* Light Steel Grey */
          color: #334e68; /* Dark Steel Grey */
          border: 1px solid #cbd5e0;

          &:hover {
            background-color: #cfd8dc; /* Darker Grey on hover */
            transform: scale(1.05);
          }

          /* Static title */
          &::after {
            color: #334e68; /* Dark Steel Grey */
          }
        `}

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
`;

export const Th = styled.th`
  background-color: rgba(156, 163, 175, 0.5);
  padding: 8px;
`;

export const Td = styled.td`
  padding: 8px;
  border: 1px solid #d1d5db;
`;

export const RelativeDiv = styled.div`
  position: relative;
  border: 1px solid #d1d5db;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 8px;
  position: relative;
`;

export const Textarea = styled.textarea<{ isValid?: boolean }>`
  width: 100%;
  padding: 4px;
  border: 1px solid ${(props) => (props.isValid ? "#d1d5db" : "#f87171")};
  outline: none;
`;

export const ImagePreviewContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  min-width: 130px;
`;

export const SelectContainer = styled.div`
  position: relative;
`;

export const ChevronIcon = styled(FaChevronDown)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const ColorInput = styled.input`
  width: 50px;
  height: 30px;
  padding: 0;
`;

export const BoxContainer = styled.div`
  border: 1px solid #ddd;
  padding: 16px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const FlexRowRight = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 8px;
`;

export const SelectNormal = styled.select<{ disabled?: boolean }>`
  width: 300px;
  padding: 4px;
  border: 1px solid #ddd;
  margin-left: 40px;

  // Apply styles when the select is disabled
  ${({ disabled }) =>
    disabled &&
    `
      background-color: #f5f5f5;
      border-color: #ccc;
      color: #999;
      pointer-events: none; // Prevent any interaction
      opacity: 0.6; // Visual feedback for disabled state
    `}
`;


export const ImagePreview = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

