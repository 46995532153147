import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isOpen: boolean;
  editId: string | null;
  editKey: string;
  editValue: string;
}

const initialState: ModalState = {
  isOpen: false,
  editId: null,
  editKey: '',
  editValue: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{ editId?: string; editKey?: string; editValue?: string }>
    ) => {
      state.isOpen = true;
      state.editId = action.payload.editId || '';
      state.editKey = action.payload.editKey || '';
      state.editValue = action.payload.editValue || '';
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.editId = null;
      state.editKey = '';
      state.editValue = '';
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
