import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { uploadFile } from '../features/appSlice';
import { getImageSrc, hexToRgba, hexToRgbaPlain } from '../utils/utils';
import { AppDispatch, RootState } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import VideoComponent from '../components/video_component';
import { createVideo, resetCreateVideoStatus } from '../features/videoSlice';
import { ToastContainer, toast } from 'react-toastify';
import BackButton from '../components/back_button';
import { Spinner } from '../styles/GlobalStyles';
import { Button } from '../styles/manage_box_page_styles';

// Styled Components
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: 1; /* Adjusts to occupy equal space */
  padding: 0 8px; /* Tighter padding */
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px; /* Reduced gap for a tighter form */
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; /* Smaller gap for more compact section */
  border-radius: 8px; /* Rounded corners */
  background-color: #f0ffff; /* Light gray background color */
  padding: 20px; /* Padding to prevent inner elements from touching the border */
`;



const Label = styled.label`
  font-weight: 600; /* Slightly reduced boldness */
  font-size: 14px; /* Adjusted font size for elegance */
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 14px; /* Smaller font for cleaner look */
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 14px; /* Consistent with Input */
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: 10px; /* Reduced gap for tighter alignment */
  align-items: center;
`;

const ImagePreview = styled.img`
  width: 50px; /* Slightly smaller preview */
  height: 50px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: -8px; /* Tighter error message placement */
`;




// Define a union type for form data
type BoxDataType = {
    type: 'text' | 'url';
    content: string;
} & (
        | {
            type: 'text'; // For 'text', bg_color and text_color are required
            bg_color: string;
            text_color: string;
        }
        | {
            type: 'url'; // For 'url', bg_color and text_color will be optional
            bg_color?: string;
            text_color?: string;
        }
    );

type FormDataType = {
    kind: string;
    video_width: string;
    video_height: string;
    video_bg: string;
    box_left_side: BoxDataType;
    box_right_side: BoxDataType;
    box_top_side: BoxDataType;
    item_1_type: 'url' | 'file';
    item_1: string;
    item_2_type: 'url' | 'file';
    item_2: string;
    item_3_type: 'url' | 'file';
    item_3: string;
};

const VideoCustomizationForm: React.FC = () => {

    const dispatch: AppDispatch = useDispatch();
    const { videos, isCreateVideoInprogress, createVideoError, createVideoStatus } = useSelector((state: RootState) => state.videos);

    const [formData, setFormData] = useState<FormDataType>({
        "kind": "0",
        video_width: '1000',
        video_height: '1000',
        video_bg: '#ffffff',
        box_left_side: {
            type: 'url',
            content: '',
            bg_color: '#ffffff',
            text_color: '#ffffff'
        },
        box_right_side: {
            type: 'url',
            content: '',
            bg_color: '#ffffff',
            text_color: '#ffffff'
        },
        box_top_side: {
            type: 'url',
            content: '',
            bg_color: '#ffffff',
            text_color: '#ffffff'
        },
        item_1_type: 'url',
        item_1: '',
        item_2_type: 'url',
        item_2: '',
        item_3_type: 'url',
        item_3: ''
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [errorMessage, setErrorMessage] = useState("");
    const [showToast, setShowToast] = useState(false);


    useEffect(() => {
        if (showToast && errorMessage) {
            // Show the toast
            toast.error(errorMessage);
            // Reset showToast to prevent repeated toasts
            setShowToast(false);
        }
    }, [showToast, errorMessage]);

    const triggerError = (message: string) => {
        setErrorMessage(message);
        setShowToast(true);
    };


    useEffect(() => {
        if (createVideoStatus === "succeeded") {
            toast("video Created!", {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetCreateVideoStatus());
        } else if (createVideoStatus === "failed") {
            toast(`Error creating item: ${createVideoError}`, {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetCreateVideoStatus());
        }
    }, [createVideoStatus, createVideoError, dispatch]);


    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const keys = name.split('.');

        setFormData((prev) => {
            const updatedSection = prev[keys[0] as keyof FormDataType];

            // Ensure the section being updated is indeed an object
            if (typeof updatedSection === 'object' && updatedSection !== null) {
                return {
                    ...prev,
                    [keys[0] as keyof FormDataType]: {
                        ...updatedSection,
                        [keys[1] as keyof typeof updatedSection]: value
                    }
                };
            }
            return {
                ...prev,
                [name as keyof FormDataType]: value
            };
        });

        // Clear the error for the field that was changed
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };


    // Create refs for file inputs
    const fileInputRefs = {
        box_left_side: useRef<HTMLInputElement | null>(null),
        box_right_side: useRef<HTMLInputElement | null>(null),
        box_top_side: useRef<HTMLInputElement | null>(null),
        item_1: useRef<HTMLInputElement | null>(null),
        item_2: useRef<HTMLInputElement | null>(null),
        item_3: useRef<HTMLInputElement | null>(null),

    };

    const handleFileUpload = (side: keyof Pick<FormDataType, 'box_left_side' | 'box_right_side' | 'box_top_side' | 'item_1' | 'item_2' | 'item_3'>) => async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const [uploadFileName, errorMessage] = await uploadFile(file); // Assuming uploadFile function is available
                if (!errorMessage) {

                    if (side === "item_1" || side === "item_2" || side === "item_3") {
                        setFormData((prevData) => ({
                            ...prevData,
                            [side]: getImageSrc(uploadFileName), // Update content with the uploaded file URL
                        }));
                    } else {
                        setFormData((prevData) => ({
                            ...prevData,
                            [side]: {
                                ...prevData[side], // Here, side is guaranteed to be BoxDataType
                                content: getImageSrc(uploadFileName), // Update content with the uploaded file URL
                            },
                        }));
                    }


                    // Clear the file input safely
                    const fileInput = fileInputRefs[side].current;
                    if (fileInput) {
                        fileInput.value = ''; // Safely clear the file input
                    }
                } else {
                    console.error("File upload error:", errorMessage);
                }
            } catch (error) {
                console.error("Unexpected error:", error);
            }
        }

        // Clear the error for the field that was changed
        setErrors((prevErrors) => ({ ...prevErrors, [side]: '' }));

        // validateForm();

    };


    const validateForm = () => {
        let formIsValid = true;
        const newErrors: { [key: string]: string } = {};

        // Add validation for required fields
        if (!formData.video_width) {
            formIsValid = false;
            newErrors.video_width = 'Video width is required';
        }

        if (!formData.video_height) {
            formIsValid = false;
            newErrors.video_height = 'Video height is required';
        }

        if (!formData.box_left_side.content) {
            formIsValid = false;
            newErrors['box_left_side.content'] = 'Box Left Side content is required';
        }

        if (!formData.box_right_side.content) {
            formIsValid = false;
            newErrors['box_right_side.content'] = 'Box Right Side content is required';
        }

        if (!formData.box_top_side.content) {
            formIsValid = false;
            newErrors['box_top_side.content'] = 'Box Top Side content is required';
        }

        if (!formData.item_1) {
            formIsValid = false;
            newErrors['item_1'] = 'Item1 is required';
        }

        if (!formData.item_2) {
            formIsValid = false;
            newErrors['item_2'] = 'Item2 is required';
        }

        if (!formData.item_3) {
            formIsValid = false;
            newErrors['item_3'] = 'Item3 is required';
        }

        // Add any other necessary validations here...

        setErrors(newErrors);

        const allErrorsString = Object.values(newErrors).join(', ');
        triggerError(allErrorsString)
        return formIsValid;
    };




    const renderPreview = (content: string, type: 'url' | 'file') => {
        if (type === 'file' && content) {
            return <ImagePreview src={content} alt="Preview" />;
        }
        if (type === 'url' && content) {
            return <ImagePreview src={content} alt="Preview" />;
        }
        return null;
    };



    // Function to clean the form data
    function cleanFormData(data: FormDataType): Omit<FormDataType, 'item_1_type' | 'item_2_type' | 'item_3_type'> {
        // Helper function to clean box sides, removing bg_color and text_color if type is 'url'
        const cleanBoxSide = (box: BoxDataType): BoxDataType => {
            if (box.type === 'url') {
                // Use destructuring to omit bg_color and text_color
                const { bg_color, text_color, ...rest } = box;
                return rest;
            }

            box.bg_color = hexToRgbaPlain(box.bg_color);
            box.text_color = hexToRgbaPlain(box.text_color);

            return box; // Return the unchanged box for type 'text'
        };

        // Clean the form data and remove the unwanted item_type properties
        const { item_1_type, item_2_type, item_3_type, ...cleanedData } = data;

        // Convert color codes in the cleaned data (video_bg and box sides)
        if (cleanedData.video_bg) {
            cleanedData.video_bg = hexToRgbaPlain(cleanedData.video_bg); // No transparency (alpha = 1)
        }


        return {
            ...cleanedData,
            box_left_side: cleanBoxSide(data.box_left_side),
            box_right_side: cleanBoxSide(data.box_right_side),
            box_top_side: cleanBoxSide(data.box_top_side),
        };

    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent the default form submission

        // Validate the form before submitting
        if (validateForm()) {
            const cleanedData = cleanFormData(formData);
            console.log(JSON.stringify(cleanedData, null, 2));

            dispatch(createVideo(cleanedData));

        } else {
            console.log('Form validation failed');
        }
    };


    return (
        <>
            <Row>
                <BackButton />
            </Row>
            <Row>
                <Column>
                    <h3>Video Settings</h3>
                </Column>
                <Column>
                    <h3>Video Demo</h3>
                </Column>
            </Row>

            <Row>
                <ToastContainer />
                <Column>
                    <FormWrapper onSubmit={handleSubmit}>  {/* Add the onSubmit handler here */}
                        {/* <h3>Video Settings</h3> */}
                        <Section>
                            <Label>Video Width:</Label>
                            <Input
                                type="number"
                                name="video_width"
                                value={formData.video_width}
                                onChange={handleChange}
                                placeholder="Enter Video Width"
                            />
                            {errors.video_width && <ErrorMessage>{errors.video_width}</ErrorMessage>}

                            <Label>Video Height:</Label>
                            <Input
                                type="number"
                                name="video_height"
                                value={formData.video_height}
                                onChange={handleChange}
                                placeholder="Enter Video Height"
                            />
                            {errors.video_height && <ErrorMessage>{errors.video_height}</ErrorMessage>}

                            <Label>Video Background Color (RGBA):</Label>
                            <Input
                                type="color"
                                name="video_bg"
                                value={formData.video_bg}
                                onChange={handleChange}
                                placeholder="Select Background Color"
                            />
                            <Label>{hexToRgbaPlain(formData.video_bg)}</Label>

                        </Section>

                        {/* Box Left Side */}
                        <h3>Box Left Side</h3>
                        <Section>
                            <Label>Type:</Label>
                            <Select name="box_left_side.type" value={formData.box_left_side.type} onChange={handleChange}>
                                <option value="text">Text</option>
                                <option value="url">Url</option>
                            </Select>
                            <Label>Content:</Label>
                            {formData.box_left_side.type === 'text' ? (
                                <>
                                    <Input
                                        type="text"
                                        name="box_left_side.content"
                                        value={formData.box_left_side.content}
                                        onChange={handleChange}
                                        placeholder="Enter text content"
                                    />
                                    {errors['box_left_side.content'] && <ErrorMessage>{errors['box_left_side.content']}</ErrorMessage>}

                                    <Label>Background Color (RGBA):</Label>
                                    <Input
                                        type="color"
                                        name="box_left_side.bg_color"
                                        value={formData.box_left_side.bg_color || ''}
                                        onChange={handleChange}
                                    />
                                    <Label>{hexToRgbaPlain(formData.box_left_side.bg_color)}</Label>

                                    <Label>Text Color (RGB):</Label>
                                    <Input
                                        type="color"
                                        name="box_left_side.text_color"
                                        value={formData.box_left_side.text_color || ''}
                                        onChange={handleChange}
                                    />
                                    <Label>{hexToRgbaPlain(formData.box_left_side.text_color)}</Label>
                                </>
                            ) : (
                                <>
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        name="box_left_side.content"
                                        ref={fileInputRefs.box_left_side} // Reference for clearing
                                        onChange={handleFileUpload('box_left_side')}
                                    />
                                    <Input
                                        type="text"
                                        name="box_left_side.content"
                                        value={formData.box_left_side.content}
                                        onChange={handleChange}
                                        placeholder="Enter image URL"
                                    />
                                    {errors['box_left_side.content'] && <ErrorMessage>{errors['box_left_side.content']}</ErrorMessage>}

                                    <PreviewContainer>
                                        {renderPreview(formData.box_left_side.content, formData.box_left_side.type)}
                                    </PreviewContainer>
                                </>
                            )}


                        </Section>

                        {/* Box Right Side */}
                        <h3>Box Right Side</h3>
                        <Section>
                            <Label>Type:</Label>
                            <Select name="box_right_side.type" value={formData.box_right_side.type} onChange={handleChange}>
                                <option value="text">Text</option>
                                <option value="url">Url</option>
                            </Select>
                            <Label>Content:</Label>
                            {formData.box_right_side.type === 'text' ? (
                                <>
                                    <Input
                                        type="text"
                                        name="box_right_side.content"
                                        value={formData.box_right_side.content}
                                        onChange={handleChange}
                                        placeholder="Enter text content"
                                    />
                                    {errors['box_right_side.content'] && <ErrorMessage>{errors['box_right_side.content']}</ErrorMessage>}

                                    <Label>Background Color (RGBA):</Label>
                                    <Input
                                        type="color"
                                        name="box_right_side.bg_color"
                                        value={formData.box_right_side.bg_color || ''}
                                        onChange={handleChange}
                                    />
                                    <Label>{hexToRgbaPlain(formData.box_right_side.bg_color)}</Label>

                                    <Label>Text Color (RGB):</Label>
                                    <Input
                                        type="color"
                                        name="box_right_side.text_color"
                                        value={formData.box_right_side.text_color || ''}
                                        onChange={handleChange}
                                    />
                                    <Label>{hexToRgbaPlain(formData.box_right_side.text_color)}</Label>

                                </>
                            ) : (
                                <>
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        name="box_right_side.content"
                                        ref={fileInputRefs.box_right_side} // Reference for clearing
                                        onChange={handleFileUpload('box_right_side')}
                                    />
                                    <Input
                                        type="text"
                                        name="box_right_side.content"
                                        value={formData.box_right_side.content}
                                        onChange={handleChange}
                                        placeholder="Enter image URL"
                                    />
                                    {errors['box_right_side.content'] && <ErrorMessage>{errors['box_right_side.content']}</ErrorMessage>}

                                    <PreviewContainer>
                                        {renderPreview(formData.box_right_side.content, formData.box_right_side.type)}
                                    </PreviewContainer>
                                </>
                            )}

                        </Section>

                        {/* Box Top Side */}
                        <h3>Box Top Side</h3>
                        <Section>
                            <Label>Type:</Label>
                            <Select name="box_top_side.type" value={formData.box_top_side.type} onChange={handleChange}>
                                <option value="text">Text</option>
                                <option value="url">Url</option>
                            </Select>
                            <Label>Content:</Label>
                            {formData.box_top_side.type === 'text' ? (
                                <>
                                    <Input
                                        type="text"
                                        name="box_top_side.content"
                                        value={formData.box_top_side.content}
                                        onChange={handleChange}
                                        placeholder="Enter text content"
                                    />
                                    {errors['box_top_side.content'] && <ErrorMessage>{errors['box_top_side.content']}</ErrorMessage>}

                                    <Label>Background Color (RGBA):</Label>
                                    <Input
                                        type="color"
                                        name="box_top_side.bg_color"
                                        value={formData.box_top_side.bg_color || ''}
                                        onChange={handleChange}
                                    />
                                    <Label>{hexToRgbaPlain(formData.box_top_side.bg_color)}</Label>

                                    <Label>Text Color (RGB):</Label>
                                    <Input
                                        type="color"
                                        name="box_top_side.text_color"
                                        value={formData.box_top_side.text_color || ''}
                                        onChange={handleChange}
                                    />
                                    <Label>{hexToRgbaPlain(formData.box_top_side.text_color)}</Label>

                                </>
                            ) : (
                                <>
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        name="box_top_side.content"
                                        ref={fileInputRefs.box_top_side} // Reference for clearing
                                        onChange={handleFileUpload('box_top_side')}
                                    />
                                    <Input
                                        type="text"
                                        name="box_top_side.content"
                                        value={formData.box_top_side.content}
                                        onChange={handleChange}
                                        placeholder="Enter image URL"
                                    />
                                    {errors['box_top_side.content'] && <ErrorMessage>{errors['box_top_side.content']}</ErrorMessage>}

                                    <PreviewContainer>
                                        {renderPreview(formData.box_top_side.content, formData.box_top_side.type)}
                                    </PreviewContainer>
                                </>
                            )}


                        </Section>

                        <h3>Items</h3>
                        <Section>
                            <Label>Item 1 (Text URL or Image):</Label>
                            <Select name="item_1_type" value={formData.item_1_type} onChange={handleChange}>
                                <option value="url">Text URL</option>
                                <option value="file">Upload Image</option>
                            </Select>
                            {formData.item_1_type === 'url' ? (
                                <Input
                                    type="text"
                                    name="item_1"
                                    value={formData.item_1}
                                    onChange={handleChange}
                                    placeholder="Enter Item 1 URL"
                                />


                            ) : (
                                <>
                                    <Input
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        name="item_1"
                                        ref={fileInputRefs.item_1} // Reference for clearing
                                        onChange={handleFileUpload('item_1')}
                                    />

                                    <Input
                                        type="text"
                                        name="item_1"
                                        value={formData.item_1}
                                        onChange={handleChange}
                                        placeholder="Enter Item 1 URL"
                                    />
                                </>
                            )}
                            {errors['item_1'] && <ErrorMessage>{errors['item_1']}</ErrorMessage>}

                            <PreviewContainer>
                                {renderPreview(formData.item_1, formData.item_1_type)}
                            </PreviewContainer>

                            <Label>Item 2 (Text URL or Image):</Label>
                            <Select name="item_2_type" value={formData.item_2_type} onChange={handleChange}>
                                <option value="url">Text URL</option>
                                <option value="file">Upload Image</option>
                            </Select>
                            {formData.item_2_type === 'url' ? (
                                <Input
                                    type="text"
                                    name="item_2"
                                    value={formData.item_2}
                                    onChange={handleChange}
                                    placeholder="Enter Item 2 URL"
                                />
                            ) : (
                                <>
                                    <Input
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        name="item_2"
                                        ref={fileInputRefs.item_2} // Reference for clearing
                                        onChange={handleFileUpload('item_2')}

                                    />

                                    <Input
                                        type="text"
                                        name="item_2"
                                        value={formData.item_2}
                                        onChange={handleChange}
                                        placeholder="Enter Item 2 URL"
                                    />
                                </>
                            )}
                            {errors['item_2'] && <ErrorMessage>{errors['item_2']}</ErrorMessage>}

                            <PreviewContainer>
                                {renderPreview(formData.item_2, formData.item_2_type)}
                            </PreviewContainer>

                            <Label>Item 3 (Text URL or Image):</Label>
                            <Select name="item_3_type" value={formData.item_3_type} onChange={handleChange}>
                                <option value="url">Text URL</option>
                                <option value="file">Upload Image</option>
                            </Select>
                            {formData.item_3_type === 'url' ? (
                                <Input
                                    type="text"
                                    name="item_3"
                                    value={formData.item_3}
                                    onChange={handleChange}
                                    placeholder="Enter Item 3 URL"
                                />
                            ) : (
                                <>
                                    <Input
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        name="item_3"
                                        ref={fileInputRefs.item_3} // Reference for clearing
                                        onChange={handleFileUpload('item_3')}

                                    />
                                    <Input
                                        type="text"
                                        name="item_3"
                                        value={formData.item_3}
                                        onChange={handleChange}
                                        placeholder="Enter Item 3 URL"
                                    />
                                </>
                            )}


                            {errors['item_3'] && <ErrorMessage>{errors['item_3']}</ErrorMessage>}

                            <PreviewContainer>
                                {renderPreview(formData.item_3, formData.item_3_type)}
                            </PreviewContainer>
                        </Section>


                        <Button
                            primary
                            type="submit"
                            disabled={isCreateVideoInprogress}
                        >
                            {isCreateVideoInprogress && <Spinner />}
                            {isCreateVideoInprogress ? '' : 'Create Video'}
                        </Button>

                    </FormWrapper>
                </Column>

                <Column>
                    <Section>
                        <Label>Video</Label>

                        {/* Display error message if video creation failed */}
                        {createVideoStatus === 'failed' && createVideoError && (
                            <p style={{ color: 'red' }}>{createVideoError}</p>
                        )}

                        {/* Show Spinner while video creation is in progress */}
                        {isCreateVideoInprogress && <Spinner />}

                        {/* Display the video once creation succeeds */}
                        {videos[0] != null ? (
                            <VideoComponent videoBase64={videos[0]} />
                        ) :
                            <p>No video available</p>
                        }
                    </Section>
                </Column>
            </Row>
        </>
    );
};

export default VideoCustomizationForm;
