import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints, BASE_URL, BASE_URL_WITHOUT_RESTRICTIONS } from "../api/endpoints";
import { BoxComputeType } from "../modal/Compute_Item";
import { Item } from "../modal/Item";
import { User } from "../modal/User";
import { ComputeItem } from "../modal/Compute_Item"; // Assuming this is the correct import
import { maxFileSize } from "../utils/utils";

// Axios instance with authorization
export const api = axios.create({
  baseURL: BASE_URL,
  headers: { Accept: "application/json", Authorization: `Bearer ${localStorage.getItem("token")}` },
});

// Axios instance without authorization restrictions
export const apiWithoutRestriction = axios.create({
  baseURL: BASE_URL_WITHOUT_RESTRICTIONS,
  headers: { Accept: "application/json" },
});

// Utility function for extracting error message
const getErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.message || error.message;
  } else if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};



interface ErrorDetail {
  loc: [string, number];
  msg: string;
  type: string;
}

interface ErrorResponse {
  detail: ErrorDetail[];
}
// Async thunk for login


// Add the signin method
export const login = createAsyncThunk<
  { token: string },
  { email: string; password: string },
  { rejectValue: ErrorResponse }
>(
  "app/login",
  async (signinData, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiWithoutRestriction.post(
        ApiEndpoints.LOGIN,
        null,
        {
          params: signinData,
          headers: {
            accept: "application/json",
          },
        }
      );

      // Store token in local storage
      const { token } = response.data;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);




export const fetch1000Items = createAsyncThunk(
  "app/fetch1000Items",
  async (_, { rejectWithValue }) => {
    try {
      const offset = 0;
      const limit = 1000;
      const response = await api.get(`${ApiEndpoints.ITEMS}?offset=${offset}&limit=${limit}&retired=${false}`);
      const itemsArray = Item.parseItems(response.data.list);
      return itemsArray;
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      console.error("Error fetching 1000 items:", errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

// Thunk for box computation
export const computeBox = createAsyncThunk(
  "app/computeBox",
  async (computeItems: BoxComputeType, { rejectWithValue }) => {
    try {
      const response = await api.post(ApiEndpoints.COMPUTE_BOX, computeItems);
      if (response.status === 200) {
        const computeItem: ComputeItem = response.data;
        console.log("Box Compute ComputeItem => " + computeItem.other_items.length);
        return computeItem;
      }
      return null;
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      console.error("Error in computeBox():", errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

// Thunk for creating an item
export const createNewItem = createAsyncThunk(
  "app/createNewItem",
  async (itemData: any, { rejectWithValue }) => {
    try {
      const response = await api.post(ApiEndpoints.ITEMS, itemData);
      return response.data;
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      console.error('Error creating item:', errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

// Thunks for fetching user information
export const fetchUserInformation = createAsyncThunk(
  "app/fetchUserInformation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(ApiEndpoints.ME);
      return response.data as User;
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      console.error("Error fetching user information:", errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);

export const fetchUserInformationWithoutRestrictions = createAsyncThunk(
  "app/fetchUserInformationWithoutRestrictions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiWithoutRestriction.get(ApiEndpoints.ME);
      const user: User = response.data;
      return user;
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      console.error("Error fetching user information without restrictions:", errorMsg);
      return rejectWithValue(errorMsg);
    }
  }
);


export const getUserInformationWithoutRestrictions = async () => {
  try {
    const response = await apiWithoutRestriction.get(ApiEndpoints.ME);
    const user : User = response.data;    
    return user;
  } catch (error) {
    console.error("Error fetching me:", error);
    throw error;
  }
};

export type UploadResult = [string, string | null]; // Tuple for uploadedFileName and errorMessage

export const uploadFile = async (file: File | null): Promise<UploadResult> => {
    let uploadedFileName = "";
    let errorMessage: string | null = null;

    if (!file) {
        console.log("No file selected");
        return [uploadedFileName, "No file selected"];
    }
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      alert(file.type);
      return [uploadedFileName, "Please select a valid image file (JPEG or PNG)."];
    }

    if (file.size >= maxFileSize) {
      return [uploadedFileName, "File too large to be uploaded"];
    }

    try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await api.post(ApiEndpoints.FILE, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status === 200) {
            uploadedFileName = response.data.name;
            console.log("Uploaded image name:", uploadedFileName);
        } else {
            errorMessage = `Upload failed with status: ${response.status}`;
            console.error(errorMessage);
        }
    } catch (error: any) {
        errorMessage = error.response?.status === 413
            ? "File too large to be uploaded"
            : "File can't be uploaded";
        console.error("Upload error:", errorMessage);
    }

    return [uploadedFileName, errorMessage];
};



// The app slice
const appSlice = createSlice({
  name: "app",
  initialState: {
    isLoading: false,
    items: [] as Item[],
    user: null as User | null,
    token: localStorage.getItem("token") || null,
    error: null as string | null,
  },
  reducers: {
    logoutUser(state) {
      state.token = null;
      state.user = null; // Optional: clear user info on logout
      localStorage.removeItem('token');
    },
  },  
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.token;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;

        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to sign in";
        state.error = errorMsg;
      })
      .addCase(fetch1000Items.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetch1000Items.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
        state.error = null;
      })
      .addCase(fetch1000Items.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(computeBox.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(computeBox.fulfilled, (state, action) => {
        state.isLoading = false;
        // Update state based on computed data
        state.error = null;
      })
      .addCase(computeBox.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(createNewItem.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createNewItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items.push(action.payload); // Assuming that new item is added to the items array
        state.error = null;
      })
      .addCase(createNewItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserInformation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(fetchUserInformation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserInformationWithoutRestrictions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserInformationWithoutRestrictions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(fetchUserInformationWithoutRestrictions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logoutUser } = appSlice.actions;
export const appReducer = appSlice.reducer;


// Utility Functions
export function hasAdminRole(user: User): boolean {
  return user.is_admin === true;
}