import React from 'react';
import styled from 'styled-components';

interface Props {
    level: number;
    handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    isDisabled?: boolean; // New prop for disabling the select
}

// Styled component for the select element
const StyledSelect = styled.select<{ isDisabled: boolean }>`
    width: 300px;
    padding: 0.25rem;
    border: 1px solid #ccc;
    outline: none;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    
    &:focus {
        border-color: #000; /* Add a focus effect */
    }
`;

const MySelect: React.FC<Props> = ({ level, handleSelectChange, isDisabled = false }) => {
    // Convert the iterable to an array before mapping
    const options = Array.from({ length: 10 }, (_, i) => i + 1);

    return (
        <StyledSelect
            value={level} // Set the selected value to the current state value
            onChange={handleSelectChange} // Add an onChange event handler
            disabled={isDisabled} // Disable the select when isDisabled is true
            isDisabled={isDisabled} // Pass the disabled state to styled component
        >
            <option value="" disabled>Select a level</option> {/* Placeholder option */}
            {options.map((value) => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </StyledSelect>
    );
};

export default MySelect;
