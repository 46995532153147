import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoxesContent, setCurrentPage, setSearchString, setSelectedBox, duplicateBox, deleteBox, resetDeleteBoxStatus, resetDuplicateBoxStatus } from "../features/boxSlice";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import noImageAvailable from "../assets/no_image.png";
import { ApiEndpoints } from "../api/endpoints";
import { FaEdit as EditIcon, FaCopy as DuplicateIcon, FaTrash as DeleteIcon } from "react-icons/fa";
import TitleCard from "../components/title_card";
import { AppDispatch, RootState } from "../app/store";
import { Box } from "../modal/box";
import { useNavigate } from "react-router-dom";
import useConfirmation from "../components/confirm_modal";
import { Icon, IconButton, Spinner } from "../styles/GlobalStyles";
import { toast, ToastContainer } from "react-toastify";
import { fetchSettings } from "../features/settingssSlice";
import { getImagePath } from "../utils/utils";

// Styled components for pagination
const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: "active",
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  padding: 0 5px;

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

// Styled components for layout and styling
const Container = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px 15px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// Define the type for the Button component's props
interface ButtonProps {
  primary?: boolean; // Optional boolean prop for primary styling
}

// Extend the Button component with the ButtonProps type
const Button = styled.button<ButtonProps>`
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;

    ${({ primary }) =>
    primary
      ? `
        background-color: #4CAF50;
        color: white;
        border: 1px solid #4CAF50;
    `
      : `
        background-color: #e2e8f0;
        color: #2d3748;
        border: 1px solid #cbd5e0;
    `}
`;

const TopSideButtons = styled.div`
    display: inline-block;
    float: right;
            padding-right: 0.5rem;

`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  td {
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 5%;
    }
    &:nth-child(4) {
      width: 10%;
    }
    &:nth-child(5) {
      width: 35%;
    }
    &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(7) {
      width: 5%;
    }
       &:nth-child(8) {
      width: 10%;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const BoxesPage: React.FC = () => {
  const { boxes, isNeedToRefresh, currentPage, totalItems, duplicateBoxId, duplicateBoxStatus, deleteBoxId, deleteBoxStatus, isDuplicateBoxInprogress, isDeleteBoxInprogress, deleteBoxError, duplicateBoxError } = useSelector(
    (state: RootState) => state.boxes
  );
  const itemsPerPage = 20;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { confirm, ConfirmationModal } = useConfirmation();

  const handlePageChange = (selectedPage: { selected: number }) => {
    dispatch(setCurrentPage(selectedPage.selected + 1));
  };

  const handleSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(setSearchString(searchQuery));
    dispatch(getBoxesContent());
  };

  const handleResetSearch = () => {
    setSearchQuery("");
    dispatch(setSearchString(""));
    dispatch(setCurrentPage(1));
    dispatch(getBoxesContent());
  };

  useEffect(() => {
    dispatch(getBoxesContent());
  }, [currentPage, isNeedToRefresh, dispatch]);


  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const updateBoxScreen = (box: Box) => {
    dispatch(setSelectedBox(box));
    navigate(`/dashboard/boxes/details/${box.id}`);
  };

  const handleAddNewIBoxClick = () => {
    dispatch(setSelectedBox(null));
    navigate(`/dashboard/boxes/details`);
  };


  const duplicateBoxAction = async (box: Box) => {
    const isConfirmed = await confirm(`Going to duplicate this box ${box.name}. Do you want to proceed?`);
    if (!isConfirmed) return;

    const boxId = box.id!; // Example box_id
    const boxName = box.name + " copy"; // Example name

    // Dispatch the async thunk action
    dispatch(duplicateBox({ id: boxId, name: boxName }))
      .unwrap()
      .then((result) => {
        console.log("Box duplicated successfully!", result);
      })
      .catch((error) => {
        console.error("Failed to duplicate the box:", error);
      });
  };

  const deleteBoxAction = async (box: Box) => {
    const isConfirmed = await confirm(`Are you sure you want to delete this box ${box.name}. Do you want to proceed?`);
    if (!isConfirmed) return;

    // Dispatch the async thunk action
    dispatch(deleteBox({ id: box.id! }))
      .unwrap()
      .then((result) => {
        console.log("Box deleted successfully!", result);
      })
      .catch((error) => {
        console.error("Failed to deleted the box:", error);
      });
  };


  // Handle updateBox status changes
  useEffect(() => {
    if (deleteBoxStatus === "succeeded") {
      toast("Box deleted", {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => { },
      });

      dispatch(resetDeleteBoxStatus());
    } else if (deleteBoxStatus === "failed") {
      toast(`Error deleting box: ${deleteBoxError}`, {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => { },
      });

      dispatch(resetDeleteBoxStatus());
    }
  }, [deleteBoxStatus, deleteBoxError, dispatch]);

  // Handle updateBox status changes
  useEffect(() => {
    if (duplicateBoxStatus === "succeeded") {
      toast("Box dupliated.", {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => { },
      });

      dispatch(resetDuplicateBoxStatus());
    } else if (duplicateBoxStatus === "failed") {
      toast(`Error duplicating box: ${duplicateBoxError}`, {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => { },
      });

      dispatch(resetDuplicateBoxStatus());
    }
  }, [duplicateBoxStatus, duplicateBoxError, dispatch]);

  return (
    <TitleCard
      title="Current Boxes"
      topMargin="mt-2"
      TopSideButtons={
        <TopSideButtons>
          <Button primary onClick={handleAddNewIBoxClick}>
            Add New
          </Button>
        </TopSideButtons>
      }
    >
      <Container>
        <ToastContainer />
        <SearchBar>
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button primary onClick={handleSearch}>
            Search
          </Button>
          <Button onClick={handleResetSearch}>Reset</Button>
        </SearchBar>
        <StyledTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Tag</th>
              <th>Level</th>
              <th>Featured</th>
              <th>Description</th>
              <th>Value</th>
              <th>Stock Count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {boxes &&
              boxes.map((box, index) => (
                <tr key={index}>
                  <td>
                    <ImageWrapper>
                      {box.picture ? (
                        <img
                          src={getImagePath(box.picture)}
                          alt="Image"
                          style={{ width: '48px', height: '48px', objectFit: 'contain' }}
                        />
                      ) : (
                        <img
                          src={noImageAvailable}
                          alt="No Image"
                          style={{ width: '48px', height: '48px', objectFit: 'contain' }}
                        />
                      )}
                      <div>{box.name}</div>
                    </ImageWrapper>
                  </td>
                  <td>{box.tags.join(", ")}</td>
                  <td>{box.level}</td>
                  <td style={{ textAlign: 'center' }}>
                    <span
                      style={{
                        color: box.is_featured ? 'green' : 'grey',
                        fontWeight: 'bold',
                      }}
                    >
                      {box.is_featured ? 'Yes' : 'No'}
                    </span>
                  </td>
                  <td>{box.description}</td>
                  <td>${box.final_value}</td>
                  <td>{box.stock_count}</td>
                  <td>
                    <IconButton onClick={() => updateBoxScreen(box)}>
                      <Icon><EditIcon /></Icon>
                    </IconButton>

                    <IconButton disabled={isDuplicateBoxInprogress} onClick={() => duplicateBoxAction(box)} paddingRight="10px">
                      {box.id === duplicateBoxId && duplicateBoxStatus === 'loading' ? (
                        <Spinner />
                      ) : (
                        <Icon><DuplicateIcon /></Icon>
                      )}
                    </IconButton>

                    <IconButton disabled={isDeleteBoxInprogress} onClick={() => deleteBoxAction(box)} paddingRight="0px">
                      {box.id === deleteBoxId && deleteBoxStatus === 'loading' ? (
                        <Spinner />
                      ) : (
                        <Icon><DeleteIcon /></Icon>
                      )}
                    </IconButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </StyledTable>
        <ConfirmationModal />

      </Container>
      <MyPaginate
        pageCount={Math.ceil(totalItems / itemsPerPage)}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        breakLabel="..."
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={currentPage - 1}
      />
    </TitleCard>
  );
};

export default BoxesPage;
