import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import BackButton from '../components/back_button';
import { AppDispatch, RootState } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { uploadFile } from '../features/appSlice';
import { Button, FlexRowRight } from '../styles/manage_box_page_styles';
import ErrorText from '../components/error_text';
import { addBanner, resetAddBannersStatus, resetUpdateBannersStatus, updateBanner } from '../features/bannersSlice';
import { ApiEndpoints } from '../api/endpoints';
import { Spinner } from '../styles/GlobalStyles';
import { getImagePath } from '../utils/utils';


// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin: 20px auto;
  background-color: #fff;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  width: 100%-20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: #666;
`;

const ImageUpload = styled.div<{ isDragActive: boolean }>`
  padding: 20px;
  border: 2px dashed ${props => props.isDragActive ? '#007bff' : '#ccc'};
  border-radius: 4px;
  text-align: center;
  color: ${props => props.isDragActive ? '#007bff' : '#999'};
  margin-bottom: 20px;
  cursor: pointer;
  background-color: ${props => props.isDragActive ? '#e6f7ff' : 'transparent'};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewImage = styled.img`
  width: 100%;
  max-width: 400px;  // Sets a maximum width
  height: auto;
  max-height: 300px; // Sets a reasonable max height
  margin-top: 10px;
  object-fit: contain; // Ensures the image scales down while keeping the aspect ratio
  border: 1px solid #ccc; // Optional: adds a border around the image
  border-radius: 4px; // Optional: adds rounded corners
`;

const HintMessage = styled.p`
  font-size: 14px;
  color: #007bff; // Change this to a color that fits your design
  margin-top: 10px;
  padding: 10px;
  border: 1px dashed #007bff; // Dashed border for emphasis
  border-radius: 4px;
  background-color: #e6f7ff; // Light background to stand out
`;


const AddUpdateBanner: React.FC = () => {
    // Access banners, loading, and error state from Redux
    const { banners, addBannersStatus, addBannersError, selectedBanner, isAddBannerInProgress, isUpdateBannerInProgress, updateBannersStatus, updateBannersError } = useSelector((state: RootState) => state.banners);

    const [active, setActive] = useState(selectedBanner?.active || false);
    const [image, setImage] = useState<File | null>(null);
    const [view_order] = useState<number>(
        selectedBanner?.order_id !== undefined && selectedBanner?.order_id !== null
            ? selectedBanner.order_id
            : banners.length
    );

    const [imageName] = useState(selectedBanner?.image || null);

    const [imageUrl] = useState(
        selectedBanner?.image
            ?  getImagePath(selectedBanner.image)
            : null
    );


    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [showToast, setShowToast] = useState(false);



    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setImage(acceptedFiles[0]);
            setErrorMessage("");
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'image/*': [] },
        multiple: false
    });


    useEffect(() => {
        if (showToast && errorMessage) {
            // Show the toast
            toast.error(errorMessage);
            // Reset showToast to prevent repeated toasts
            setShowToast(false);
        }
    }, [showToast, errorMessage]);

    useEffect(() => {
        if (addBannersStatus === "succeeded") {
            toast("New Item Created!", {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetAddBannersStatus());
        } else if (addBannersStatus === "failed") {
            toast(`Error creating item: ${addBannersError}`, {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetAddBannersStatus());
        }
    }, [addBannersStatus, addBannersError, dispatch]);

    useEffect(() => {
        if (updateBannersStatus === "succeeded") {
            toast("Updated banner successfully!", {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetUpdateBannersStatus());
        } else if (updateBannersStatus === "failed") {
            toast(`Error updating banner: ${updateBannersError}`, {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetUpdateBannersStatus());
        }
    }, [updateBannersStatus, updateBannersError, dispatch]);

    const triggerError = (message: string) => {
        setErrorMessage(message);
        setShowToast(true);
    };

    const handleAddOrUpdateBannerClick = async () => {
        // Helper function to handle file uploads and error triggering
        const handleFileUpload = async (file: File | null) => {
            const [uploadFileName, errorMessage] = await uploadFile(file);
            if (errorMessage) {
                triggerError(errorMessage);
                return null;
            }
            return uploadFileName;
        };

        // If editing an existing banner
        if (selectedBanner && selectedBanner.id) {
            let finalImageName = imageName;

            // If new image is provided, handle upload
            if (image) {
                finalImageName = await handleFileUpload(image);
                if (!finalImageName) return; // Exit if there was an error during upload
            }

            // Dispatch update banner action
            dispatch(updateBanner({
                banner_id: selectedBanner.id,
                image: finalImageName!,
                order_id: selectedBanner.order_id,
                active: active,
            }));

        } else {
            // Creating a new banner, upload image first
            const uploadFileName = await handleFileUpload(image);
            if (!uploadFileName) return; // Exit if there was an error during upload

            // Dispatch add banner action
            dispatch(addBanner({
                image: uploadFileName,
                order_id: view_order,
                active: active,
            }));
        }
    };


    const cancelAction = () => {
        navigate(-1);
    };



    return (
        <>
            <BackButton />
            <Container>
                <ToastContainer />
                <Title> {selectedBanner ? "Edit Banner" : "New Banner"}</Title>
                <Label htmlFor="viewOrder">View Order</Label>
                <Input id="viewOrder" disabled={true} type="number" placeholder="Enter view order" value={view_order + 1} />

                <CheckboxContainer>
                    <input
                        id="active"
                        type="checkbox"
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                    />
                    <CheckboxLabel htmlFor="active">Active</CheckboxLabel>
                </CheckboxContainer>

                {/* <Label htmlFor="url">Url</Label>
                <Input id="url" type="text" placeholder="Enter banner url" disabled={true} value={`${imageUrl}`}/> */}
                <Label>Image</Label>
                <ImageUpload isDragActive={isDragActive} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here...</p>
                    ) : (
                        <p>Drop image file here to upload (or click)</p>
                    )}
                </ImageUpload>

                {/* Hint message for image dimensions */}
                <HintMessage>For optimal display on the website, upload images with a 3:1 aspect ratio. Recommended dimensions: 3072px (width) x 1024px (height).</HintMessage>

                {/* Only show the image preview if either image or imageUrl is present */}
                {(image || imageUrl) && (
                    <PreviewImage
                        src={image ? URL.createObjectURL(image) : `${imageUrl}`}
                        alt="Banner Preview"
                    />
                )}
                <FlexRowRight>
                    <ErrorText>{errorMessage}</ErrorText>
                </FlexRowRight>

                <ButtonContainer>
                    <Button onClick={cancelAction}>Add Banner</Button>
                    <Button
                        primary
                        onClick={handleAddOrUpdateBannerClick}
                        disabled={isAddBannerInProgress || isUpdateBannerInProgress}
                    >
                        {(isAddBannerInProgress || isUpdateBannerInProgress) && <Spinner />}
                        {(isAddBannerInProgress || isUpdateBannerInProgress) ? '' : (selectedBanner === null ? 'Add Banner' : 'Update Banner')}
                    </Button>
                </ButtonContainer>
            </Container>
        </>

    );
};

export default AddUpdateBanner;
